import React, { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import TemplateDefault from '../../templates/default';
import SEO from '../../components/seo';
import GardenExplorer from '../../components/organism/garden-explorer';
import AtomTitle from '../../components/atom/title';
import ResourceList from '../../components/paragraphs/resource-list';

const placeholderImage = '../../images/placeholder.jpg';

export default function ResourcesPage() {
  const query = useStaticQuery(graphql`
    query {
      topics: allTaxonomyTermAnbgLhTopic {
        edges {
          node {
            id: drupal_internal__tid
            name
          }
        }
      }
    }
  `);
  const falseNode = useMemo(
    () => ({
      filter: true,
      relationships: {
        topics: query.topics.edges.map((topic) => ({ ...topic.node })),
      },
    }),
    [query.topics],
  );

  return (
    <TemplateDefault>
      <GardenExplorer />

      <main className="tw-bg-anbg-mid-green">
        <section className="  tw-py-20 tw-px-4 xl:tw-py-24 2xl:tw-py-32">
          <div className="tw-mx-auto tw-w-full tw-max-w-screen-2xl tw-items-center tw-justify-center tw-text-anbg-offblack md:tw-space-x-10 xl:tw-flex xl:tw-space-x-20 2xl:tw-space-x-32">
            <div className="xl:tw-w-1/3">
              <StaticImage src={placeholderImage} quality={100} alt="" />
            </div>
            <div className="tw-flex tw-flex-col xl:tw-w-2/3">
              <div className="tw-pb-8">
                <AtomTitle title="Teacher Resources" />
              </div>
              <div className="tw-prose tw-max-w-none tw-text-anbg-offblack xl:tw-prose-lg">
                This Resource Hub aims to provide teachers Lorem ipsum dolor sit
                amet, consectetur adipiscing elit. Donec rutrum lacus eu leo
                ultricies, non consectetur quam pellentesque. In sed egestas
                arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Donec rutrum lacus eu leo ultricies, non consectetur quam
                pellentesque. In sed egestas arcu.
              </div>
            </div>
          </div>
        </section>
        <section>
          <ResourceList node={falseNode} />
        </section>
      </main>
    </TemplateDefault>
  );
}

export function Head() {
  return <SEO />;
}
